import $api from "../http";

export default class B24Service {
  static async getGroups() {
    return $api.get("/b24/channels");
  }

  static async createGroup(name: string, description: string) {
    return $api.post("/b24/creategroup", { name, description });
  }

  static async selectGroup(groupId: number, chainId: string, name: string) {
    return $api.post("/b24/node", { groupId, chainId, name });
  }

  static async removeGroup(chainId: string, nodeId: number) {
    return $api.delete("/b24/node", { data: { chainId, nodeId } });
  }
}
