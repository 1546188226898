import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useStore } from "../hooks/useStore";
import { Info } from "../components/Info";
import { ChainIcon } from "../components/Icons";
import { Logo } from "../components/Logo";
import { ChainNode } from "../components/ChainNode";
import styles from "./css/chain-page.module.css";

export const ChainPage: FC = observer(() => {
  const {
    ppStore: { isLoading, chain, inputs, thisNode, deleteB24Channel },
  } = useStore();

  return (
    <div className={styles.page}>
      <Logo />
      <div className={styles.header}>
        <div className={styles.title}>Моя цепочка парапостинга</div>
        <div className={styles.chain}>
          <ChainIcon color='gradient' />
          <div className={styles.color}>{chain?.name || chain?.id}</div>
        </div>
      </div>
      <div className={styles.wrapper}>
        {!!inputs?.length ? (
          inputs.map((item) => (
            <ChainNode
              key={item.link}
              item={item}
              type='input'
              text='Репосты идут из этой группы'
            />
          ))
        ) : (
          <div className={styles.err}>Нет входящих каналов!</div>
        )}
        {thisNode && (
          <ChainNode
            item={thisNode}
            type='output'
            text='В эту группу публикуются посты из Paraposter'
            onDelete={() => deleteB24Channel()}
            loading={isLoading}
          />
        )}
      </div>
      <Info>
        Настройте{" "}
        <a href='https://helpdesk.bitrix24.ru/open/10104989/' target='_blank'>
          вывод новостей
        </a>{" "}
        выбранной группы на вашем сайте bitrix 24
      </Info>
    </div>
  );
});
