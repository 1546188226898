import { FC, useEffect, useState } from "react";
import { Button } from "../components/Button";
import { Progressbar } from "../components/Progressbar";
import { Code } from "../components/Code";
import { Info } from "../components/Info";
import { Logo } from "../components/Logo";
import { ChainIcon } from "../components/Icons";
import { useStore } from "../hooks/useStore";
import { CONNECTION_URL, STORAGE_TOKEN_NAME } from "../http";
import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";
import { B24Group } from "../store/interfaces/paraposter.interfaces";
import { ChainNode } from "../components/ChainNode";
import styles from "./css/connect-page.module.css";

export const ConnectPage: FC = observer(() => {
  const [group, setGroup] = useState<B24Group | null>(null);
  const [code, setCode] = useState<number | null>(null);
  const [qr, setQr] = useState<string | null>(null);
  const [listenLink, setLink] = useState<string>("");
  const [connect, setConnect] = useState<boolean>(false);
  const {
    ppStore: { setChain, selectB24Group, chain, removeConnection },
    setError,
  } = useStore();

  const { id } = useParams();

  useEffect(() => {
    const group = selectB24Group(Number(id));
    if (!group) return;
    setGroup(group);
    setLink(
      `${CONNECTION_URL}?type=output&name=${
        group.name
      }&node=${id}&tkn=${localStorage.getItem(STORAGE_TOKEN_NAME)}`
    );
  }, [id]);

  useEffect(() => {
    if (connect) {
      subscribe();
    }
  }, [connect]);

  const subscribe = async () => {
    const eventSource = new EventSource(listenLink);
    eventSource.onerror = function (event) {
      setCode(null);
      setConnect(false);
      eventSource.close();
    };
    eventSource.onmessage = function (event) {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case "code":
          setCode(data.code);
          if (data.qr) {
            setQr(data.qr);
          }
          break;

        case "connect":
          setChain(data.chain);
          break;

        case "error":
          setCode(null);
          setConnect(false);
          if (data.message) {
            setError(data.message);
            eventSource.close();
          }
          break;

        default:
          break;
      }
    };
  };

  return (
    <div className={styles.connectBox}>
      <Logo />
      {group && <ChainNode item={group} text={group.description} />}
      <div className={styles.body}>
        {code ? (
          <>
            <Code code={code} />
            {qr && (
              <div
                className={styles.qrcode}
                dangerouslySetInnerHTML={{ __html: qr }}
              />
            )}
            <Progressbar />
          </>
        ) : (
          <>
            <Button
              text='Подключить к Paraposter'
              icon={<ChainIcon />}
              onClick={() => setConnect(true)}
            />
            <Link to={"/setup"}>Выбрать другую группу</Link>
          </>
        )}
      </div>
      {code && (
        <Info text='Откройте приложение Paraposter в VK и введите этот код при добавлении входящего канала' />
      )}
      {chain && (
        <div style={{ fontSize: "12px", color: "var(--dark-warm)" }}>
          Подключено к аккаунту Paraposter.{" "}
          <a onClick={removeConnection} href='#disconnect'>
            Отключить
          </a>
        </div>
      )}
    </div>
  );
});
