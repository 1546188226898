import { FC, PropsWithChildren } from "react";
import styles from "./info.module.css";

interface InfoProps extends PropsWithChildren {
   text?: string;
   size?: "normal" | "small";
}

export const Info: FC<InfoProps> = ({ text, size = "normal", children }) => {
   const style = size === "normal" ? styles.info : styles.info + " " + styles.small;
   if (children) return <div className={style}>{children}</div>;
   return <div className={style}>{text}</div>;
};
