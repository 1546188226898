import { makeAutoObservable } from "mobx";
import AuthService from "../service/auth.service";
import { STORAGE_TOKEN_NAME } from "../http";
import { B24Group, Chain, Node } from "./interfaces/paraposter.interfaces";
import B24Service from "../service/b24.service";
import ConnectionService from "../service/connection.service";

const waiting = (miliseconds?: number) => {
   const time = miliseconds || 300;
   return new Promise((resolve, reject) => {
      setTimeout(() => resolve("wait for " + time + "ms"), time);
   });
};

export class ParaposterStore {
   b24url: string | null = null;
   chain: Chain | null = null;
   b24Groups: B24Group[] | null = null;
   thisNode: Node | null = null;
   isLoading: boolean = false;
   isLogin: boolean = false;
   error: any;

   constructor() {
      makeAutoObservable(this, undefined, { autoBind: true });
   }

   setUrl(url: string) {
      this.b24url = url;
   }

   setLoading(value: boolean) {
      this.isLoading = value;
   }

   setError(e: any) {
      console.error("ParaPoster store Error - ", e);
      this.error = e;
      throw "Что-то пошло не так";
   }

   get isGroupSelected() {
      return !!this.thisNode?.options;
   }

   setChain(chain: Chain | null) {
      this.chain = chain;
      if (!chain) this.thisNode = null;
      if (chain?.nodes.length && this.b24url) {
         const node = chain.nodes.find((item) => item.link.includes(this.b24url || ""));
         if (node) {
            this.thisNode = node;
         } else {
            this.thisNode = null;
         }
      }
   }

   setNode(node: Node) {
      this.thisNode = node;
   }

   setLogin(value: boolean) {
      this.isLogin = value;
   }

   setB24Groups(data: B24Group[] | null) {
      this.b24Groups = data;
   }

   get inputs() {
      if (this.chain && this.chain.nodes.length) {
         const nodes = this.chain.nodes.filter((item) => item.type === "input");
         return nodes || null;
      }
      return null;
   }

   get output() {
      if (this.thisNode && this.thisNode.options) return this.thisNode.options;
      return null;
   }

   async login() {
      this.setLoading(true);
      try {
         const searchParams = Object.fromEntries(new URLSearchParams(window.location.search));
         this.setUrl(searchParams.domain);
         const { data } = await AuthService.login(searchParams);

         if (!data?.ntk) return this.setError("");
         localStorage.setItem(STORAGE_TOKEN_NAME, data.ntk);
         if (data.chain) {
            const chain = data.chain as Chain;
            this.setChain(chain);

            if (chain?.nodes.length && this.b24url) {
               const node = chain.nodes.find((item) => item.link.includes(this.b24url || ""));
               if (node) {
                  if (!node.options) {
                     const { data } = await B24Service.getGroups();
                     this.setB24Groups(data);
                  }
               }
            }
         }
         this.setLogin(true);
      } catch (e) {
         this.setError(e);
      } finally {
         this.setLoading(false);
      }
   }

   async getB24Channels() {
      this.setLoading(true);
      try {
         const { data } = await B24Service.getGroups();
         this.setB24Groups(data);
      } catch (e) {
         this.setError(e);
      } finally {
         this.setLoading(false);
      }
   }

   selectB24Group(id: number) {
      if (!this.b24Groups || !this.b24Groups.length) return null;
      const group = this.b24Groups.find((item) => item.id === id);
      return group || null;
   }

   async createB24Channel(values: { name: string; description: string }) {
      this.setLoading(true);
      try {
         const { data } = await B24Service.createGroup(values.name, values.description);
         if (data?.ok) await this.getB24Channels();
         return data?.group || null;
      } catch (e) {
         this.setError(e);
      } finally {
         this.setLoading(false);
      }
   }

   async selectB24Channel(groupId: number) {
      this.setLoading(true);
      try {
         await waiting();
         if (!this.chain) return this.setError("No chain selected");
         if (!this.b24Groups || !this.b24Groups.length) return this.setError("B24 group error");

         let groupName: string | null = null;
         const node = this.b24Groups.find((item) => item.id === groupId);

         if (!node) return this.setError("B24 group error");
         groupName = node.name;

         const { data } = await B24Service.selectGroup(groupId, this.chain.id, groupName);
         this.setChain(data);
      } catch (e) {
         this.setError(e);
      } finally {
         this.setLoading(false);
      }
   }

   async deleteB24Channel() {
      this.setLoading(true);
      if (!this.chain || !this.chain.id || !this.thisNode?.id)
         return this.setError("Error data to delete");
      try {
         await waiting();
         const { data } = await B24Service.removeGroup(this.chain.id, this.thisNode.id);
         if (data.ok && this.thisNode) {
            this.setChain(null);
         }
      } catch (e) {
         this.setError(e);
      } finally {
         this.setLoading(false);
      }
   }

   async removeConnection() {
      this.setLoading(true);
      try {
         const data = await ConnectionService.disconnect();
         if (data.status === 200) {
            this.setChain(null);
         }
      } catch (e) {
      } finally {
         this.setLoading(false);
      }
   }
}
