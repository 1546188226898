import { CSSProperties, FC } from "react";
import styles from "./spinner.module.css";

type sizeType = "normal" | "small" | "micro";

interface SpinnerProps {
   color?: string;
   opacity?: number;
   size?: sizeType;
   style?: CSSProperties;
}

const getSize = (size: sizeType) => {
   let px: number;

   switch (size) {
      case "normal":
         px = 24;
         break;

      case "micro":
         px = 12;
         break;

      case "small":
         px = 16;
         break;

      default:
         px = 24;
         break;
   }
   return px;
};

export const Spinner: FC<SpinnerProps> = ({ color, opacity, size = "normal", style }) => {
   return (
      <svg
         width={getSize(size)}
         height={getSize(size)}
         viewBox='0 0 24 24'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'
         className={styles.loader}
         style={{ opacity: `${opacity ?? 1}`, ...style }}
      >
         <g clipPath='url(#clip0_322_283)'>
            <path
               fillRule='evenodd'
               clipRule='evenodd'
               d='M7.39162 2.71332C7.9445 3.48189 7.76966 4.55314 7.00109 5.10603C4.83407 6.66493 3.42857 9.20318 3.42857 12.0698C3.42857 16.8036 7.26613 20.6412 12 20.6412C16.7339 20.6412 20.5714 16.8036 20.5714 12.0698C20.5714 9.20318 19.1659 6.66493 16.9989 5.10603C16.2303 4.55314 16.0555 3.48189 16.6084 2.71332C17.1613 1.94476 18.2325 1.76991 19.0011 2.3228C22.0252 4.49823 24 8.05339 24 12.0698C24 18.6972 18.6274 24.0698 12 24.0698C5.37258 24.0698 0 18.6972 0 12.0698C0 8.05339 1.97484 4.49823 4.99891 2.3228C5.76748 1.76991 6.83873 1.94476 7.39162 2.71332Z'
               fill={color ? color : "url(#paint0_radial_322_283)"}
            />
         </g>
         {color ? null : (
            <defs>
               <radialGradient
                  id='paint0_radial_322_283'
                  cx='0'
                  cy='0'
                  r='1'
                  gradientUnits='userSpaceOnUse'
                  gradientTransform='translate(22.3153 3.74886) rotate(136.779) scale(23.0598 21.8078)'
               >
                  <stop stopColor='#AB00D6' />
                  <stop offset='1' stopColor='#5585FF' />
               </radialGradient>
            </defs>
         )}
      </svg>
   );
};
