import { FC } from "react";

interface LogoProps {
  noGradientFill?: boolean;
  color?: string;
  startColor?: string;
  stopColor?: string;
}

export const Logo: FC<LogoProps> = ({
  noGradientFill,
  color,
  startColor,
  stopColor,
}) => {
  return (
    <svg
      width='148'
      height='59'
      viewBox='0 0 148 59'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M18.9602 58.4805C19.5966 58.4805 20.1124 57.9646 20.1124 57.3282L20.1124 43.1777C20.1124 42.5413 20.6283 42.0254 21.2647 42.0254L24.2909 42.0255C24.5965 42.0255 24.8896 41.9041 25.1057 41.688L28.2862 38.5075C29.0121 37.7816 28.498 36.5404 27.4714 36.5404L21.2647 36.5405C20.6283 36.5405 20.1124 36.0246 20.1124 35.3882L20.1124 11.8974C20.1124 10.8708 18.8713 10.3567 18.1454 11.0826L14.965 14.263C14.7489 14.4791 14.6275 14.7722 14.6275 15.0778L14.6275 35.3882C14.6275 36.0246 14.1116 36.5405 13.4752 36.5405L11.8849 36.5405C11.8849 36.5405 0.915047 36.5405 0.915048 47.5104C0.915049 58.4802 11.8849 58.4805 11.8849 58.4805L18.9602 58.4805ZM14.6275 51.8432C14.6275 52.4795 14.1115 52.9986 13.4752 52.9906C9.85664 52.945 6.40003 52.4697 6.39998 47.5104C6.39993 42.7172 10.5887 42.1127 13.4755 42.0364C14.1116 42.0196 14.6275 42.5413 14.6275 43.1777L14.6275 51.8432Z'
        fill={`${noGradientFill ? color : "url(#ppg0)"}`}
      />
      <path
        d='M37.4987 42.1387C36.6094 42.1387 35.8024 41.9712 35.0776 41.6362C34.3528 41.2951 33.7773 40.8048 33.3509 40.1653C32.9246 39.5197 32.7114 38.7401 32.7114 37.8265C32.7114 37.0225 32.8698 36.3708 33.1865 35.8714C33.5032 35.3659 33.9265 34.97 34.4564 34.6837C34.9863 34.3975 35.571 34.1843 36.2105 34.0442C36.8561 33.8981 37.5047 33.7823 38.1564 33.6971C39.0091 33.5874 39.7004 33.5052 40.2303 33.4504C40.7663 33.3895 41.1561 33.289 41.3997 33.1489C41.6494 33.0088 41.7743 32.7652 41.7743 32.418V32.3449C41.7743 31.4435 41.5276 30.7431 41.0343 30.2437C40.547 29.7442 39.807 29.4945 38.8142 29.4945C37.7849 29.4945 36.9779 29.7199 36.3932 30.1706C35.8085 30.6213 35.3974 31.1025 35.1599 31.6141L33.1134 30.8832C33.4788 30.0305 33.9661 29.3666 34.5752 28.8916C35.1903 28.4104 35.8603 28.0754 36.5851 27.8866C37.3159 27.6917 38.0346 27.5943 38.7411 27.5943C39.1918 27.5943 39.7096 27.6491 40.2943 27.7587C40.885 27.8622 41.4545 28.0785 42.0027 28.4074C42.5569 28.7362 43.0168 29.2326 43.3822 29.8965C43.7476 30.5604 43.9304 31.4496 43.9304 32.5642V41.8098H41.7743V39.9095H41.6646C41.5185 40.214 41.2748 40.5399 40.9338 40.887C40.5927 41.2342 40.1389 41.5296 39.5725 41.7732C39.0061 42.0169 38.3148 42.1387 37.4987 42.1387ZM37.8275 40.2018C38.6802 40.2018 39.3989 40.0344 39.9836 39.6994C40.5744 39.3644 41.019 38.932 41.3175 38.4021C41.622 37.8722 41.7743 37.3149 41.7743 36.7302V34.7568C41.6829 34.8665 41.4819 34.967 41.1713 35.0583C40.8668 35.1436 40.5135 35.2197 40.1115 35.2867C39.7156 35.3476 39.3289 35.4024 38.9513 35.4512C38.5797 35.4938 38.2783 35.5303 38.0468 35.5608C37.4865 35.6339 36.9627 35.7526 36.4754 35.9171C35.9943 36.0754 35.6045 36.316 35.306 36.6388C35.0137 36.9555 34.8675 37.388 34.8675 37.9361C34.8675 38.6853 35.1446 39.2517 35.6989 39.6354C36.2592 40.013 36.9688 40.2018 37.8275 40.2018Z'
        fill={`${noGradientFill ? color : "url(#ppg1)"}`}
      />
      <path
        d='M47.8657 41.8098V27.777H49.9487V29.8965H50.0948C50.3506 29.2022 50.8135 28.6388 51.4835 28.2064C52.1535 27.7739 52.9087 27.5577 53.7492 27.5577C53.9076 27.5577 54.1055 27.5608 54.343 27.5668C54.5806 27.5729 54.7602 27.5821 54.8821 27.5943V29.7869C54.809 29.7686 54.6415 29.7412 54.3796 29.7047C54.1238 29.662 53.8527 29.6407 53.5665 29.6407C52.8843 29.6407 52.2753 29.7838 51.7393 30.0701C51.2094 30.3503 50.7892 30.7401 50.4785 31.2395C50.174 31.7328 50.0217 32.2962 50.0217 32.9296V41.8098H47.8657Z'
        fill={`${noGradientFill ? color : "url(#ppg1)"}`}
      />
      <path
        d='M61.5673 42.1387C60.6781 42.1387 59.871 41.9712 59.1463 41.6362C58.4215 41.2951 57.8459 40.8048 57.4196 40.1653C56.9932 39.5197 56.7801 38.7401 56.7801 37.8265C56.7801 37.0225 56.9384 36.3708 57.2551 35.8714C57.5718 35.3659 57.9951 34.97 58.525 34.6837C59.0549 34.3975 59.6396 34.1843 60.2791 34.0442C60.9247 33.8981 61.5734 33.7823 62.2251 33.6971C63.0778 33.5874 63.769 33.5052 64.2989 33.4504C64.8349 33.3895 65.2247 33.289 65.4683 33.1489C65.718 33.0088 65.8429 32.7652 65.8429 32.418V32.3449C65.8429 31.4435 65.5962 30.7431 65.1029 30.2437C64.6156 29.7442 63.8756 29.4945 62.8829 29.4945C61.8535 29.4945 61.0465 29.7199 60.4618 30.1706C59.8771 30.6213 59.466 31.1025 59.2285 31.6141L57.182 30.8832C57.5475 30.0305 58.0347 29.3666 58.6438 28.8916C59.2589 28.4104 59.9289 28.0754 60.6537 27.8866C61.3846 27.6917 62.1033 27.5943 62.8098 27.5943C63.2605 27.5943 63.7782 27.6491 64.3629 27.7587C64.9537 27.8622 65.5231 28.0785 66.0713 28.4074C66.6255 28.7362 67.0854 29.2326 67.4508 29.8965C67.8163 30.5604 67.999 31.4496 67.999 32.5642V41.8098H65.8429V39.9095H65.7333C65.5871 40.214 65.3435 40.5399 65.0024 40.887C64.6613 41.2342 64.2076 41.5296 63.6411 41.7732C63.0747 42.0169 62.3834 42.1387 61.5673 42.1387ZM61.8962 40.2018C62.7489 40.2018 63.4676 40.0344 64.0523 39.6994C64.643 39.3644 65.0877 38.932 65.3861 38.4021C65.6906 37.8722 65.8429 37.3149 65.8429 36.7302V34.7568C65.7515 34.8665 65.5506 34.967 65.2399 35.0583C64.9354 35.1436 64.5821 35.2197 64.1802 35.2867C63.7843 35.3476 63.3975 35.4024 63.0199 35.4512C62.6484 35.4938 62.3469 35.5303 62.1154 35.5608C61.5551 35.6339 61.0313 35.7526 60.5441 35.9171C60.0629 36.0754 59.6731 36.316 59.3747 36.6388C59.0823 36.9555 58.9361 37.388 58.9361 37.9361C58.9361 38.6853 59.2133 39.2517 59.7675 39.6354C60.3278 40.013 61.0374 40.2018 61.8962 40.2018Z'
        fill={`${noGradientFill ? color : "url(#ppg1)"}`}
      />
      <path
        d='M71.9343 47.0721V27.777H74.0173V30.0061H74.2731C74.4314 29.7625 74.6507 29.4519 74.9309 29.0743C75.2171 28.6906 75.6252 28.3495 76.1551 28.0511C76.6911 27.7465 77.4158 27.5943 78.3294 27.5943C79.511 27.5943 80.5525 27.8896 81.4539 28.4804C82.3553 29.0712 83.0588 29.9087 83.5643 30.9928C84.0699 32.077 84.3226 33.356 84.3226 34.8299C84.3226 36.316 84.0699 37.6042 83.5643 38.6944C83.0588 39.7786 82.3584 40.6191 81.4631 41.2159C80.5677 41.8067 79.5354 42.1021 78.366 42.1021C77.4646 42.1021 76.7428 41.9529 76.2008 41.6545C75.6587 41.3499 75.2415 41.0058 74.9491 40.6221C74.6568 40.2323 74.4314 39.9095 74.2731 39.6537H74.0904V47.0721H71.9343ZM74.0538 34.7934C74.0538 35.8531 74.2091 36.7881 74.5198 37.5981C74.8304 38.4021 75.2841 39.0324 75.881 39.4892C76.4779 39.94 77.2088 40.1653 78.0736 40.1653C78.975 40.1653 79.7272 39.9278 80.3302 39.4527C80.9393 38.9715 81.3961 38.3259 81.7006 37.5159C82.0112 36.6997 82.1665 35.7922 82.1665 34.7934C82.1665 33.8067 82.0143 32.9175 81.7097 32.1257C81.4113 31.3278 80.9575 30.6974 80.3485 30.2345C79.7455 29.7656 78.9872 29.5311 78.0736 29.5311C77.1966 29.5311 76.4596 29.7534 75.8627 30.198C75.2659 30.6365 74.8151 31.2517 74.5106 32.0435C74.2061 32.8291 74.0538 33.7458 74.0538 34.7934Z'
        fill={`${noGradientFill ? color : "url(#ppg1)"}`}
      />
      <path
        d='M93.3124 42.1021C92.0455 42.1021 90.934 41.8006 89.9778 41.1977C89.0276 40.5947 88.2846 39.7511 87.7486 38.667C87.2187 37.5829 86.9538 36.316 86.9538 34.8665C86.9538 33.4047 87.2187 32.1287 87.7486 31.0385C88.2846 29.9483 89.0276 29.1017 89.9778 28.4987C90.934 27.8957 92.0455 27.5943 93.3124 27.5943C94.5792 27.5943 95.6877 27.8957 96.6379 28.4987C97.5941 29.1017 98.3371 29.9483 98.867 31.0385C99.403 32.1287 99.671 33.4047 99.671 34.8665C99.671 36.316 99.403 37.5829 98.867 38.667C98.3371 39.7511 97.5941 40.5947 96.6379 41.1977C95.6877 41.8006 94.5792 42.1021 93.3124 42.1021ZM93.3124 40.1653C94.2747 40.1653 95.0665 39.9186 95.6877 39.4253C96.309 38.932 96.7688 38.2833 97.0672 37.4793C97.3657 36.6754 97.5149 35.8044 97.5149 34.8665C97.5149 33.9285 97.3657 33.0545 97.0672 32.2444C96.7688 31.4344 96.309 30.7797 95.6877 30.2802C95.0665 29.7808 94.2747 29.5311 93.3124 29.5311C92.3501 29.5311 91.5583 29.7808 90.937 30.2802C90.3158 30.7797 89.8559 31.4344 89.5575 32.2444C89.2591 33.0545 89.1098 33.9285 89.1098 34.8665C89.1098 35.8044 89.2591 36.6754 89.5575 37.4793C89.8559 38.2833 90.3158 38.932 90.937 39.4253C91.5583 39.9186 92.3501 40.1653 93.3124 40.1653Z'
        fill={`${noGradientFill ? color : "url(#ppg1)"}`}
      />
      <path
        d='M112.902 30.9197L110.965 31.4679C110.843 31.1451 110.664 30.8314 110.426 30.5269C110.195 30.2163 109.878 29.9605 109.476 29.7595C109.074 29.5585 108.559 29.458 107.932 29.458C107.073 29.458 106.358 29.6559 105.785 30.0518C105.219 30.4416 104.936 30.938 104.936 31.541C104.936 32.077 105.13 32.5003 105.52 32.8109C105.91 33.1215 106.519 33.3803 107.347 33.5874L109.43 34.099C110.685 34.4036 111.62 34.8695 112.235 35.4968C112.85 36.1181 113.158 36.919 113.158 37.8996C113.158 38.7036 112.926 39.4222 112.464 40.0557C112.007 40.6891 111.367 41.1885 110.545 41.554C109.723 41.9194 108.767 42.1021 107.676 42.1021C106.245 42.1021 105.06 41.7915 104.122 41.1703C103.185 40.549 102.591 39.6415 102.341 38.4477L104.387 37.9361C104.582 38.6914 104.951 39.2578 105.493 39.6354C106.041 40.013 106.757 40.2018 107.64 40.2018C108.645 40.2018 109.443 39.9887 110.033 39.5623C110.63 39.1299 110.929 38.6122 110.929 38.0092C110.929 37.522 110.758 37.1139 110.417 36.785C110.076 36.45 109.552 36.2003 108.846 36.0359L106.507 35.4877C105.222 35.1832 104.278 34.7112 103.675 34.0716C103.078 33.426 102.779 32.619 102.779 31.6506C102.779 30.8588 103.002 30.1584 103.446 29.5493C103.897 28.9403 104.509 28.4622 105.283 28.115C106.062 27.7678 106.945 27.5943 107.932 27.5943C109.321 27.5943 110.411 27.8988 111.203 28.5078C112.001 29.1169 112.567 29.9209 112.902 30.9197Z'
        fill={`${noGradientFill ? color : "url(#ppg1)"}`}
      />
      <path
        d='M122.506 27.777V29.6042H115.234V27.777H122.506ZM117.354 24.4149H119.51V37.79C119.51 38.399 119.598 38.8558 119.775 39.1604C119.957 39.4588 120.189 39.6598 120.469 39.7633C120.755 39.8608 121.057 39.9095 121.373 39.9095C121.611 39.9095 121.806 39.8973 121.958 39.873C122.11 39.8425 122.232 39.8181 122.324 39.7999L122.762 41.7367C122.616 41.7915 122.412 41.8463 122.15 41.9011C121.888 41.962 121.556 41.9925 121.154 41.9925C120.545 41.9925 119.948 41.8615 119.364 41.5996C118.785 41.3377 118.304 40.9388 117.92 40.4028C117.542 39.8669 117.354 39.1908 117.354 38.3747V24.4149Z'
        fill={`${noGradientFill ? color : "url(#ppg1)"}`}
      />
      <path
        d='M131.482 42.1021C130.13 42.1021 128.964 41.8037 127.983 41.2068C127.009 40.6038 126.257 39.7633 125.727 38.6853C125.203 37.6012 124.941 36.3404 124.941 34.903C124.941 33.4656 125.203 32.1988 125.727 31.1025C126.257 30.0001 126.994 29.1413 127.938 28.5261C128.888 27.9049 129.996 27.5943 131.263 27.5943C131.994 27.5943 132.716 27.7161 133.428 27.9597C134.141 28.2033 134.79 28.5992 135.374 29.1474C135.959 29.6894 136.425 30.4081 136.772 31.3034C137.119 32.1988 137.293 33.3012 137.293 34.6107V35.5242H126.476V33.6605H135.1C135.1 32.8687 134.942 32.1622 134.625 31.541C134.314 30.9197 133.87 30.4294 133.291 30.0701C132.719 29.7107 132.043 29.5311 131.263 29.5311C130.404 29.5311 129.661 29.7442 129.034 30.1706C128.413 30.5908 127.935 31.139 127.6 31.8151C127.265 32.4911 127.097 33.2159 127.097 33.9894V35.2319C127.097 36.2917 127.28 37.19 127.645 37.927C128.017 38.6579 128.531 39.2152 129.189 39.5989C129.847 39.9765 130.611 40.1653 131.482 40.1653C132.049 40.1653 132.56 40.0861 133.017 39.9278C133.48 39.7633 133.879 39.5197 134.214 39.1969C134.549 38.868 134.808 38.4599 134.991 37.9727L137.074 38.5574C136.854 39.2639 136.486 39.8851 135.968 40.4211C135.45 40.951 134.811 41.3652 134.05 41.6636C133.288 41.9559 132.432 42.1021 131.482 42.1021Z'
        fill={`${noGradientFill ? color : "url(#ppg1)"}`}
      />
      <path
        d='M140.573 41.8098V27.777H142.656V29.8965H142.802C143.058 29.2022 143.52 28.6388 144.19 28.2064C144.86 27.7739 145.616 27.5577 146.456 27.5577C146.614 27.5577 146.812 27.5608 147.05 27.5668C147.287 27.5729 147.467 27.5821 147.589 27.5943V29.7869C147.516 29.7686 147.348 29.7412 147.087 29.7047C146.831 29.662 146.56 29.6407 146.273 29.6407C145.591 29.6407 144.982 29.7838 144.446 30.0701C143.916 30.3503 143.496 30.7401 143.185 31.2395C142.881 31.7328 142.729 32.2962 142.729 32.9296V41.8098H140.573Z'
        fill={`${noGradientFill ? color : "url(#ppg1)"}`}
      />
      <path
        d='M24.4267 0.48053C23.7903 0.48053 23.2744 0.996422 23.2744 1.63281V15.7833C23.2744 16.4197 22.7585 16.9356 22.1221 16.9356L12.7584 16.9356C12.4528 16.9356 12.1598 17.057 11.9437 17.273L8.76315 20.4535C8.03725 21.1794 8.55136 22.4206 9.57793 22.4206L22.1221 22.4205C22.7585 22.4205 23.2744 22.9364 23.2744 23.5728V47.0636C23.2744 48.0902 24.5156 48.6043 25.2415 47.8784L28.4218 44.698C28.6379 44.4819 28.7593 44.1888 28.7593 43.8832V23.5728C28.7593 22.9364 29.2752 22.4205 29.9116 22.4205H31.5019C31.5019 22.4205 42.4718 22.4206 42.4718 11.4507C42.4718 0.480847 31.5019 0.48053 31.5019 0.48053L24.4267 0.48053ZM28.7593 7.11787C28.7593 6.48149 29.2753 5.96241 29.9116 5.97043C33.5302 6.01598 36.9868 6.49132 36.9869 11.4507C36.9869 16.2439 32.7982 16.8483 29.9114 16.9246C29.2752 16.9414 28.7593 16.4197 28.7593 15.7833L28.7593 7.11787Z'
        fill={`${noGradientFill ? color : "url(#ppg2)"}`}
      />
      {noGradientFill || (
        <>
          <defs>
            <radialGradient
              id='ppg0'
              cx='0'
              cy='0'
              r='1'
              gradientUnits='userSpaceOnUse'
              gradientTransform='translate(2.86028 54.6977) rotate(-60.4029) scale(39.2839 31.971)'
            >
              <stop stopColor={`${startColor || "#AB00D6"}`} />
              <stop offset='1' stopColor={`${stopColor || "#5585FF"}`} />
            </radialGradient>
            <radialGradient
              id='ppg1'
              cx='0'
              cy='0'
              r='1'
              gradientUnits='userSpaceOnUse'
              gradientTransform='translate(139.525 26.2103) rotate(168.604) scale(82.0508 30.1172)'
            >
              <stop stopColor={`${startColor || "#AB00D6"}`} />
              <stop offset='1' stopColor={`${stopColor || "#5585FF"}`} />
            </radialGradient>
            <radialGradient
              id='ppg2'
              cx='0'
              cy='0'
              r='1'
              gradientUnits='userSpaceOnUse'
              gradientTransform='translate(40.0817 4.26337) rotate(124.912) scale(41.6546 37.0471)'
            >
              <stop stopColor={`${startColor || "#AB00D6"}`} />
              <stop offset='1' stopColor={`${stopColor || "#5585FF"}`} />
            </radialGradient>
          </defs>
        </>
      )}
    </svg>
  );
};
