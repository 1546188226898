import $api from "../http";

export default class ConnectionService {
  static async listenCode() {
    return $api.post("/connector/node/code.listen", { type: "output" });
  }
  static async disconnect() {
    return $api.post("/connector/disconnect");
  }
}
