import { FC, useEffect, useState } from "react";
import styles from "./code.module.css";

interface CodeProps {
   code: number;
   onClick?: () => void;
}

export const Code: FC<CodeProps> = ({ code }) => {
   const [numbers, setNumbers] = useState<string[]>([]);

   useEffect(() => {
      const codeConvert = code.toString().split("");
      setNumbers(codeConvert);
   }, [code]);
   const convertCode = () => {};

   return (
      <div className={styles.code}>
         {numbers.map((num, idx) => (
            <div key={idx}>{num}</div>
         ))}
      </div>
   );
};
