import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useStore } from "../../hooks/useStore";
import style from "./error.module.css";
import { CloseIcon } from "../Icons";

interface ErrorProps {
   errorTitle?: string;
   onClose?: () => void;
   timeout?: number;
}

export const Error: FC<ErrorProps> = observer(({ errorTitle, onClose, timeout }) => {
   const { error, clearError } = useStore();
   const [title, setTitle] = useState<string>("Что-то пошло не так");
   const [message, setMessage] = useState<string>("");
   const [isOpen, setOpen] = useState<boolean>(false);

   const closeHandler = () => {
      setOpen(false);
      if (onClose) onClose();
   };

   useEffect(() => {
      if (error) {
         setOpen(true);
         if (typeof error === "string") {
            setMessage(error);
         }
         if (typeof error === "object") {
            setTitle(error.title);
            setMessage(error.message);
         }

         if (timeout) {
            setTimeout(() => {
               setOpen(false);
            }, timeout);
         }
      }
   }, [error]);

   useEffect(() => {
      if (!isOpen && error) {
         setTimeout(() => {
            clearError();
         }, 400);
      }
   }, [isOpen]);

   if (!error) return <></>;

   return (
      <div className={`${style.box} ${isOpen ? style.open : style.hide}`}>
         <div className={style.close} onClick={closeHandler}>
            <CloseIcon color='gradient' size={20} />
         </div>
         <div className={style.title}>{title}</div>
         <div className={style.text}>{message}</div>
      </div>
   );
});
