import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreContext } from "./hooks/useStore";
import { Store } from "./store";
import { BrowserRouter } from "react-router-dom";
import "./styles/colors.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
   <StoreContext.Provider value={new Store()}>
      {/* <React.StrictMode> */}
      <BrowserRouter>
         <App />
      </BrowserRouter>
      {/* </React.StrictMode> */}
   </StoreContext.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
